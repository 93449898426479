import React from "react";
import HomeBanner from "../components/Home/homeBanner";
import HomeSlider from "../components/Home/homeSlider";

import image from "../assets/1b.jpg";
import image1 from "../assets/2a.JPG";
import image2 from "../assets/6i.JPG";
import image3 from "../assets/image00006.JPG";
import image4 from "../assets/IMG_1292.JPG";
import image5 from "../assets/IMG_3699.JPG";
import image6 from "../assets/5d.JPG";
import image7 from "../assets/7d.JPG";
import image8 from "../assets/IMG_0101.JPG";
import HomeShowCase from "../components/Home/homeShowcase";
import HomeVideo from "../components/Home/homeVideo";


const Home = () => {
  const slides = [image, image1, image3, image2, image4, image5, image6, image7, image8];
  return (
    <div className="w-full bg-black">
      <HomeBanner />
      <HomeSlider slides={slides} />
      <HomeShowCase />
      <HomeVideo />
      
    </div>
  );
};

export default Home;
