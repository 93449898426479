import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const FloatingIconBar = () => {
  return (
    <div className="fixed left-0 top-1/2 transform -translate-y-1/2 z-50">
      <a
         href="https://www.facebook.com/profile.php?id=61553884228621&mibextid=YMEMSu"
        className="bg-blue-500 text-white rounded-full py-2 px-4 block text-center hover:bg-blue-700"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFacebook} size="lg" />
      </a>
      <a
        href="https://www.instagram.com/codesign_studios?igsh=OGQ5ZDc2ODk2ZA=="
        className="bg-pink-500 text-white rounded-full py-2 px-4 block text-center hover:bg-pink-700"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} size="lg" />
      </a>
      <a
         href="https://wa.me/03452316633"
        className="bg-green-500 text-white rounded-full py-2 px-4 block text-center hover:bg-green-700"
        target="_blank"
      >
        <FontAwesomeIcon icon={faWhatsapp} size="lg" />
      </a>
      
    </div>
  );
};

export default FloatingIconBar;