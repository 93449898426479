import React, { useState } from 'react';
import v1 from "../../assets/homevideo/1.mp4";
import v2 from "../../assets/homevideo/2.mp4";
import v3 from "../../assets/homevideo/3.mp4";
import v4 from "../../assets/homevideo/4.mp4";
import v5 from "../../assets/homevideo/5.mp4";

const HomeVideo = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const videos = [
    { id: 1, videoUrl: v5 },
    { id: 2, videoUrl: v1 },
    { id: 3, videoUrl: v3 },
    { id: 4, videoUrl: v4 },
    { id: 5, videoUrl: v2 },
  ];

  const handleVideoClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      <h1 className="text-5xl md:text-6xl text-orange-600 font-bold text-center mb-12" style={{ fontFamily: 'Mostin-Black' }}>
        Branded Content
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {videos.slice(0, 3).map((video) => (
          <div
            key={video.id}
            className="relative rounded-lg overflow-hidden shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
            onClick={() => handleVideoClick(video.videoUrl)}
          >
            <video
              src={video.videoUrl}
              className="w-full h-full object-cover"
              loop
              muted
              autoPlay
            />
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        {videos.slice(3).map((video) => (
          <div
            key={video.id}
            className="relative rounded-lg overflow-hidden shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
            onClick={() => handleVideoClick(video.videoUrl)}
          >
            <video
              src={video.videoUrl}
              className="w-full h-full object-cover"
              loop
              muted
              autoPlay
            />
          </div>
        ))}
      </div>
      {selectedVideo && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 p-4">
          <div className="relative bg-white rounded-lg shadow-lg w-full max-w-2xl p-4 max-h-[80vh]">
            <video
              src={selectedVideo}
              className="w-full h-auto rounded-lg max-h-[70vh]"
              controls
              autoPlay
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-white bg-orange-600 hover:bg-orange-800 p-2 rounded-full focus:outline-none transition duration-300 z-60"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeVideo;








// import React from 'react';
// import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
// import '@splidejs/splide/dist/css/splide.min.css';
// import '../fontstyle.css';
// import v1 from "../../assets/homevideo/1.mp4";
// import v2 from "../../assets/homevideo/2.mp4";
// import v3 from "../../assets/homevideo/3.mp4";
// import v4 from "../../assets/homevideo/4.mp4";
// import v5 from "../../assets/homevideo/5.mp4";

// const HomeVideo = () => {
//   const videos = [
//     { id: 1, videoUrl: v2, },
//     { id: 2, videoUrl: v1, },
//     { id: 3, videoUrl: v3, },
//     { id: 4, videoUrl: v4, },
//     { id: 5, videoUrl: v5, },
//   ];

//   return (
//     <div className="w-full max-w-3xl mx-auto p-4 relative">
//       <div>
//         <h1 className="text-4xl md:text-6xl text-orange-500 font-bold text-center my-8" style={{ fontFamily: 'Mostin-Black' }}>Brand Content</h1>
//       </div>
//       <Splide
//         options={{
//           type: 'loop',
//           autoplay: true,
//           interval: 5000,
//           perPage: 1,
//           gap: '10px',
//           pauseOnHover: false,
//           pauseOnFocus: false,
//           resetProgress: false, 
//           arrows: true,
//           pagination: false,
//         }}
//         hasTrack={false}
//         aria-label="Video Carousel"
//         className="rounded-lg overflow-hidden shadow-lg"
//       >
//         <SplideTrack>
//           {videos.map((video) => (
//             <SplideSlide key={video.id}>
//               <div className="relative">
//                 <video
//                   src={video.videoUrl}
//                   className="w-full "
//                   autoPlay
//                   loop
//                   muted
//                 />
//               </div>
//             </SplideSlide>
//           ))}
//         </SplideTrack>       
//         <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-300 my-slider-progress">
//           <div className="my-slider-progress-bar bg-orange-500 h-full transition-all duration-500"></div>
//         </div>
//       </Splide>
//     </div>
//   );
// };

// export default HomeVideo;




