import React from "react";
import CameraMan from "../../assets/Untitled-1.gif";
import '../fontstyle.css';

function PhotographyWebsite() {
  return (
    <div className="bg-black text-white container mx-auto pt-10  md:grid md:grid-cols-2 md:gap-8 p-4 md:p-16">
      <div className="md:order-1"> 
          <img
            src={CameraMan}
            className="h-90 w-90 mx-auto mb-8"
          />
      </div>
      <div className="md:order-2 mx-4 md:mx-0 ">
        <h1 className="text-5xl lg:text-7xl font-bold mb-4 text-orange-500" style={{ fontFamily: 'Mostin-Black' }}>
          WELCOME TO CODESIGN STUDIO
        </h1>
        <div className="text-4xl lg:text-5xl  mb-4" style={{ fontFamily: 'MostinLight-jEJWO' }}>
          Capturing Moments, Creating Memories
        </div>
        <p className="text-lg mb-4" style={{ fontFamily: 'MostinThin-x3pxV' }}>
          We specialize in landscape, portrait, videos, product photography, and
          event photography.
        </p>
        <p className="text-lg mb-4" style={{ fontFamily: 'MostinThin-x3pxV' }}>
          Explore our best work and feel free to get in touch for inquiries or
          photoshoot scheduling.
        </p>
        <a className="explore-button bg-orange-500 hover:bg-orange-700 text-white font-semibold py-3 px-8 rounded-full" style={{ fontFamily: 'MostinThin-x3pxV' }} href="/portfolio">
          Explore Our Work
        </a>
      </div>
    </div>
  );
}

export default PhotographyWebsite;




// import React, { useState, useEffect } from "react";
// import CameraMan from "../../assets/Untitled-1.gif";
// import '../fontstyle.css';

// function PhotographyWebsite() {
//   const [rotateX, setRotateX] = useState(0);
//   const [rotateY, setRotateY] = useState(0);

//   const handleMouseMove = (event) => {
//     const { clientX, clientY } = event;
//     const centerX = window.innerWidth / 2;
//     const centerY = window.innerHeight / 2;

//     const angleX = ((clientY - centerY) / centerY) * 20; 
//     const angleY = -((clientX - centerX) / centerX) * 20; 
    
//     setRotateX(angleX);
//     setRotateY(angleY);
//   };

//   useEffect(() => {
//     window.addEventListener("mousemove", handleMouseMove);
//     return () => {
//       window.removeEventListener("mousemove", handleMouseMove);
//     };
//   }, []);

//   return (
//     <div className="bg-black text-white container mx-auto pt-10 md:grid md:grid-cols-2 md:gap-8 p-4 md:p-16">
//       <div className="md:order-1">
//         <div
//           className="relative"
//           style={{
//             perspective: '1200px', 
//           }}
//         >
//           <img
//             src={CameraMan}
//             className="h-90 w-90 mx-auto mb-8"
//             style={{
//               transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
//               transition: 'transform 0.1s ease-out', 
//               transformStyle: 'preserve-3d', 
//               boxShadow: `0 10px 20px rgba(0, 0, 0, 0.5)`, 
//             }}
//           />
//         </div>
//       </div>
//       <div className="md:order-2 mx-4 md:mx-0">
//         <h1
//           className="text-5xl lg:text-7xl font-bold mb-4 text-orange-500"
//           style={{ fontFamily: 'Mostin-Black' }}
//         >
//           WELCOME TO CODESIGN STUDIO
//         </h1>
//         <div
//           className="text-4xl lg:text-5xl  mb-4"
//           style={{ fontFamily: 'MostinLight-jEJWO' }}
//         >
//           Capturing Moments, Creating Memories
//         </div>
//         <p
//           className="text-lg mb-4"
//           style={{ fontFamily: 'MostinThin-x3pxV' }}
//         >
//           We specialize in landscape, portrait, videos, product photography, and
//           event photography.
//         </p>
//         <p
//           className="text-lg mb-4"
//           style={{ fontFamily: 'MostinThin-x3pxV' }}
//         >
//           Explore our best work and feel free to get in touch for inquiries or
//           photoshoot scheduling.
//         </p>
//         <a
//           className="explore-button bg-orange-500 hover:bg-orange-700 text-white font-semibold py-3 px-8 rounded-full"
//           style={{ fontFamily: 'MostinThin-x3pxV' }}
//           href="/portfolio"
//         >
//           Explore Our Work
//         </a>
//       </div>
//     </div>
//   );
// }

// export default PhotographyWebsite;












