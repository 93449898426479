import React from "react";
import ModelHeading from "../components/Model/modelHeading";
import ModelGrid from "../components/Model/modelGrid";


const ModelPhotoGraphy = () => {
  return (
    <div className="w-full bg-black">
      <ModelHeading />
      <ModelGrid />
     
      
    </div>
  );
};

export default ModelPhotoGraphy;