import React from "react";
import AboutBanner from "../components/About/aboutBanner";
import AboutStory from "../components/About/aboutStory";
import AboutServices from "../components/About/aboutServices";


const AboutUs = () => {
  return (
    <div className="w-full bg-black">
      <AboutBanner />
      <AboutStory />
      <AboutServices />

    </div>
  );
};

export default AboutUs;
