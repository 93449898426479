import React from "react";
import "font-awesome/css/font-awesome.min.css";
import PricingBanner from "../components/Pricing/pricingBanner";
import PricingGrid from "../components/Pricing/pricingGrid";


const Pricing = () => {
  return (
    <div className="w-full bg-black">
      <PricingBanner />
      <PricingGrid />
    </div>
  );
};

export default Pricing;
