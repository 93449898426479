import React from 'react';
import { FaMoneyBillWaveAlt } from 'react-icons/fa'; 

function PricingBanner() {
  return (
    <div className="p-8 text-white relative">
      <div className="container mx-auto text-center">
        <div className="flex items-center justify-center mb-4 animate-bounce">
          <FaMoneyBillWaveAlt className="text-8xl font-bold mr-2 text-orange-500" />
          <h1 className="text-5xl font-bold text-orange-500">Choose Your Pricing Plan</h1>
        </div>
        <p className="text-2xl font-bold">Affordable and flexible options for everyone.</p>
      </div>
    </div>
  );
}

export default PricingBanner;
